.btn-secondary {
  background-color: transparent !important;
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid #E5E5E5 !important; 
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.btn-success {
  background-color: #82C341 !important;
  color: black !important;
  font-weight: 600 !important;
  border: none !important; 
  box-sizing: border-box !important;
  border-radius: 4px !important;
}